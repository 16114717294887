<template>
  <v-container class="authentication-wrapper">
    <div class="authentication-wrapper__background" v-if="bgUrl">
      <img :src="bgUrl" />
    </div>

      <v-form ref="form" lazy-validation @submit.prevent="signIn" id="form">
      <v-card v-if="showSignIn" class="px-8">
        <v-card-title class="pt-8 pb-12 px-0 h5">
          {{ $t('user.signIn.title', { brand: brandSchema.name }) }}
        </v-card-title>
        <v-card-text class="px-0 mb-4">
          <v-text-field
            id="email"
            class="mb-2"
            data-test="custom-email-input"
            :error-messages="[...emailErrors, ...emailOrPasswordError]"
            v-model.trim="$v.email.$model"
            :placeholder="$t('user.amplify.Enter your email')"
            outlined
          ></v-text-field>
          <v-text-field
            id="password"
            data-test="custom-sign-in-password-input"
            v-model="$v.password.$model"
            :error-messages="[...passwordErrors, ...emailOrPasswordError]"
            :placeholder="$t('user.amplify.Enter your password')"
            :type="passwordVisible ? 'text' : 'password'"
            @click:append="passwordVisible = !passwordVisible"
            :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
            outlined
          ></v-text-field>

          <v-row class="align-center wrapper-btn mt-4" no-gutters>
            <v-col>
              <v-checkbox
                hide-details="auto"
                class="ma-0 pa-0"
                v-model="checked"
                :label="$t('user.signIn.stayLoggedIn')"
              >
                Stay logged in
              </v-checkbox>
            </v-col>
            <v-col>
              <BrandedButton
                type="tertiary"
                color="primary"
                text
                :title="$t('user.amplify.Forgot Password')"
                :onClick="showResetPassword"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="error && showUserMigrationMessage" class="px-0">
          <span
            v-html="
              $t('user.amplify.UserMigration', {
                // shows only for exporo
                resetLink: isExporo
                  ? ''
                  : `<a class='color-text-primary-light' href='${$t('user.amplify.UserMigration', {
                      context: 'linkPath',
                    })}'
                >
                  ${$t('user.amplify.UserMigration', { context: 'linkTitle' })}
                </a>`,
              })
            "
          />
        </v-card-text>
        <v-card-text class="px-0">
          <BrandedButton
            type="primary"
            color="secondary"
            data-test="custom-sign-in-sign-in-button"
            block
            :title="$t('user.amplify.Sign In')"
            :disabled="loading"
            :loading="loading"
            :onClick="signIn"
          />
          <v-divider class="mt-8 mb-6" />

          <v-row class="pa-0 pb-4 register-row">
            <v-col cols="6" class="text-center">
              {{ $t('user.amplify.No account') }}
            </v-col>
            <v-col cols="6" class="text-center">
              <BrandedButton
                type="tertiary"
                color="primary"
                text
                :title="$t('user.amplify.Sign Up')"
                :onClick="signUpURL"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { AmplifyEventBus } from 'aws-amplify-vue';
import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import { request } from '@/utils/services';
import { mapGetters } from 'vuex';
import { required, email, minLength } from 'vuelidate/lib/validators';
import { supportMultiTLDForSafariCookies } from '@/utils/helpers';
import { brandNames } from '@/enum/brands';
import { resumeSession } from '@/utils/Auth';
import BrandedButton from '@/components/reusable/BrandedButton';

export default {
  name: 'SignIn',
  components: {BrandedButton},
  created() {
    AmplifyEventBus.$on('authState', async (info) => {
      this.loading = true;
      this.status = info;

      if (info === 'signedIn') {
        await this.onSignedIn();
      }

      this.loading = false;
    });
  },
  async mounted() {
    const { refreshToken } = this.$route.query;
    const { email } = this.$route.query;
    const { referral_id } = this.$route.query;
    if (email) {
      this.email = email;
    }
    this.referral_id = referral_id;

    const { registerSession } = this.$store.getters;

    if(this.$route.query.redirectURL && this.$route.query.redirectURL.includes('#/app/')) {
      localStorage.setItem('resumeAppUrl', window.location.href);
      this.$i18n.changeLanguage(this.$route.query.locale || 'de-DE');
    }
    if(refreshToken){
      const session = await resumeSession(refreshToken);
      if(session){
        await this.onSignedIn(session);
        
        return;
      }
    }

    if (!!registerSession) {
      this.showSignIn = false;
      this.email = registerSession.email;
      this.password = registerSession.password;
      this.$store.commit('setRegisterSession', undefined);

      this.signIn();
    }

    this.$store.commit('setLoaded', { loaded: true });
  },
  computed: {
    ...mapGetters(['brandSchema']),
    isExporo() {
      return this.brandSchema.name === brandNames.EXPORO;
    },
    bgUrl() {
      if (!this.brandSchema.backgroundImage) {
        return null;
      }

      return require(`@/assets/${this.brandSchema.backgroundImage}`);
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push(this.$t('user.amplify.Password cannot be empty'));

      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t('user.amplify.Invalid email format'));
      !this.$v.email.required && errors.push(this.$t('user.amplify.Email required'));

      return errors;
    },
  },
  data() {
    return {
      showSignIn: true,
      loading: false,
      email: '',
      password: '',
      enteredPassword: '',
      signedIn: false,
      error: null,
      emailOrPasswordError: [],
      referral_id: null,
      authConfig: {
        signInConfig: {
          header: '',
        },
        forgotPasswordConfig: {
          header: '',
        },
        signUpConfig: {
          header: '',
          hiddenDefaults: ['username', 'phone_number', 'agb'],
        },
        confirmSignUpConfig: {
          header: '',
        },
        confirmSignInConfig: {
          header: '',
        },
        usernameAttributes: 'email',
      },
      checked: false,
      status: 'signIn',
      passwordVisible: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  methods: {
    simulateSubmit: function (){
      document.getElementById('form').submit();
    },
    onSignedIn: async function (currentSession = null) {
      currentSession =  currentSession ? currentSession : await Auth.currentSession();
        const params = {
          refreshToken: currentSession.refreshToken.token,
          idToken: currentSession.idToken.jwtToken,
          redirectURL: this.$route.query.redirectURL
            ? this.$route.query.redirectURL
            : `${window.location.origin}${window.location.pathname}`,
          withLegacyCookie: this.isExporo ? true : false,
          persists: this.checked,
        };

        if (params.redirectURL) {
          request(`${supportMultiTLDForSafariCookies(params.redirectURL)}/user/session`, params);
        }
    },
    checkEmailVerified: async function (user) {
      if (user && user.attributes && user.attributes.email_verified === true) {
        return true;
      }

      return this.navigateActivation(user, 'email');
    },
    showResetPassword: function () {
      // AmplifyEventBus.$emit('authState', 'forgotPassword');
      this.$router.push({ name: 'ResetPassword', query: { ...this.$route.query } });
    },
    signUpURL() {
      return window.open(process.env.VUE_APP_REGISTER_URL.replace('exporo', this.brandSchema.name.toLowerCase()));
    },
    signIn: async function () {
      this.emailOrPasswordError = [];
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.error = null;
      this.loading = true;
      const { redirectURL } = this.$route.query;

      const clientMetadata = {
        pathname: window.location.pathname,
        host: window.location.host,
        locale: this.$i18n.language,
        redirectURL,
      };

      const authResponse = await Auth.signIn(this.email.toLowerCase(), this.password, clientMetadata).catch((error) => {
        this.showSignIn = true;
        if (error.code === 'UserNotConfirmedException') {
          this.emailOrPasswordError = [this.parseErrorMessage(error.code)];

          return;
        }
        this.error = error.message;

        const err = this.parseErrorMessage(error.message);
        this.emailOrPasswordError.push(err);

        const exception = new Error();
        exception.name = `Auth.signIn - ${error.code}`;
        exception.message = error.message;
        Sentry.captureException(exception);
      });

      if (_.get(authResponse, 'signInUserSession.refreshToken')) {
        this.showSignIn = false;
        AmplifyEventBus.$emit('authState', 'signedIn');
      }
      this.loading = false;
    },
    navigateActivation: function (user = null, attributeVerificationValue = 'none') {
      const { redirectURL } = this.$route.query;
      const returnURL = redirectURL ? redirectURL : `${window.location.origin}${window.location.pathname}#/`;
      this.$router.push({
        name: 'Activation',
        params: { user: user },
        query: {
          ...this.$route.query,
          redirectURL: returnURL,
          attributeVerification: attributeVerificationValue,
          ...this.email,
          ...this.referral_id,
        },
      });
    },
    parseErrorMessage(error) {
      this.showUserMigrationMessage = false;
      let keyExists = false;
      let key;
      let errorMessage;

      if (error.includes('Incorrect username or password')) {
        key = 'user.amplify.Incorrect username or password';
      } else if (error.includes('UserMigration failed')) {
        this.showUserMigrationMessage = true;

        return null;
      }

      if (error.includes('User does not exist')) {
        key = 'user.amplify.Incorrect username or password';
      }

      if (error.includes('Password reset required')) {
        key = 'user.amplify.Password reset required';
      }

      if (!key) {
        key = `user.amplify.${error}`;
      }

      keyExists = this.$i18n.exists(key);

      if (keyExists) {
        errorMessage = this.$t(key);
      } else {
        errorMessage = this.$t('user.amplify.GenericError', { errorCode: error.code || 'SignIn' });
      }

      return errorMessage;
    },
    togglePassword() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/auth.scss';
@import '@/style/media.scss';

.authentication-wrapper {
  .v-card {
    @include media('<=450px') {
      margin: 0 !important;
      padding-bottom: 0 !important;

      .wrapper-btn {
        flex-direction: column-reverse !important;
        text-align: center;
        ::v-deep .v-input,
        input,
        label {
          width: auto;
        }
        ::v-deep .v-input__control,
        ::v-deep .v-input__slot {
          width: auto;
          margin: 8px auto 0;
        }
      }
    }
  }
  .register-row {
    align-items: center;
  }
}
</style>
