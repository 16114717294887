<template>
  <div class="send-email-icon">
    <svg
      class="auto"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="160"
      viewBox="0 0 160 160"
      style="enable-background: new 0 0 160 160"
      xml:space="preserve"
    >
      <circle class="st0" cx="80" cy="80" r="80" />
      <g>
        <path class="st1" d="M37.2,71.8l42.8-31.7l43.2,33.9l-41,24.2L60,93.2L37.2,71.8z" />
        <path
          class="st2"
          d="M125.5,70.4L125.5,70.4l-18.8,8L81,89.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1l-26.5-11l-19.2-8l0,0h0
		c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v49.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3h90.9c0.3,0,0.6-0.1,0.8-0.3
		c0.2-0.2,0.3-0.5,0.3-0.8V71.5c0-0.3-0.1-0.6-0.3-0.8C126.1,70.5,125.8,70.4,125.5,70.4z M126.3,121.1c0,0.2-0.1,0.4-0.2,0.6
		c-0.2,0.2-0.4,0.2-0.6,0.2H34.5c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6V71.5c0-0.2,0.1-0.4,0.2-0.6
		c0.1-0.2,0.3-0.2,0.6-0.3l19.3,8l26.4,10.9c0.3,0.1,0.6,0.1,0.9,0l25.6-10.9l18.8-8c0.2,0,0.4,0.1,0.6,0.3c0.1,0.2,0.2,0.4,0.2,0.6
		V121.1z"
        />
        <path
          class="st2"
          d="M126.4,69.7C126.4,69.7,126.4,69.7,126.4,69.7L81.1,38.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0.1-0.5,0.1
		L35.3,69.6c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1L80,38c0.2-0.1,0.4-0.2,0.7-0.2
		c0.2,0,0.5,0.1,0.7,0.2l45.2,31.4c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1C126.6,69.6,126.6,69.6,126.4,69.7
		C126.5,69.7,126.5,69.7,126.4,69.7L126.4,69.7z"
        />
        <path
          class="st3"
          d="M80.6,88.2c-0.2,0-0.3,0-0.5-0.1L53.7,77.2V42.9c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3h50.7
		c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8v34.3l0,0L81.1,88.1C81,88.2,80.8,88.2,80.6,88.2z"
        />
        <path
          class="st4"
          d="M76.1,81.5c-1,0-1.9-0.3-2.7-0.9l0,0l-10.2-7.8c-0.5-0.4-0.9-0.8-1.2-1.3c-0.3-0.5-0.5-1.1-0.6-1.7
		c-0.1-0.6,0-1.2,0.1-1.8c0.2-0.6,0.4-1.1,0.8-1.6c0.4-0.5,0.8-0.9,1.3-1.2c0.5-0.3,1.1-0.5,1.7-0.6c0.6-0.1,1.2,0,1.8,0.1
		c0.6,0.2,1.1,0.4,1.6,0.8l6.6,5l15.6-20.3c0.4-0.5,0.8-0.9,1.3-1.2c0.5-0.3,1.1-0.5,1.7-0.6c0.6-0.1,1.2,0,1.8,0.1
		c0.6,0.2,1.1,0.4,1.6,0.8l-0.1,0.1l0.1-0.1c1,0.7,1.6,1.8,1.7,3c0.2,1.2-0.2,2.4-0.9,3.3L79.7,79.7c-0.4,0.5-1,1-1.6,1.3
		C77.5,81.3,76.8,81.5,76.1,81.5z"
        />
        <path
          class="st4"
          d="M49.3,107.6h-7.6c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2s-0.2-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.4
		c0-0.1,0-0.3,0.1-0.4c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2-0.1,0.4-0.1h7.6c0.1,0,0.3,0,0.4,0.1
		c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0,0.3-0.1,0.4c0,0.1-0.1,0.2-0.2,0.3
		c-0.1,0.1-0.2,0.2-0.3,0.2C49.5,107.6,49.4,107.6,49.3,107.6z"
        />
        <path
          class="st4"
          d="M59.3,111.2H41.7c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.2-0.3
		c0-0.1-0.1-0.2-0.1-0.4s0-0.3,0.1-0.4c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2-0.1,0.4-0.1h17.6
		c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.4s0,0.3-0.1,0.4c0,0.1-0.1,0.2-0.2,0.3
		c-0.1,0.1-0.2,0.2-0.3,0.2C59.5,111.1,59.4,111.2,59.3,111.2z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ConfirmIcon',
  props: {
    color: {
      type: String,
      default: '#eaedf2',
    },
  },
  computed: {
    light() {
      return `rgba(${this.color}, .5)`;
    },
  },
};
</script>
<style lang="scss">
@import '@/style/colors';

.st0 {
  fill: #e7f3f1;
}
.st1 {
  fill: #c4e4de;
}
.st2 {
  fill: #2e2d2c;
  stroke: #2e2d2c;
  stroke-width: 2;
}
.st3 {
  fill: #ffffff;
}
.st4 {
  fill: #409281;
}

.send-email-icon {
  display: flex;
  justify-content: space-around;
}
</style>