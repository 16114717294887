<template>
  <v-container class="authentication-wrapper reset">
    <div class="authentication-wrapper__background" v-if="bgUrl">
      <img :src="bgUrl" />
    </div>
    <v-form ref="form">
      <!-- GET CODE -->
      <v-card v-if="step === steps.GET_CODE" class="pa-4">
        <v-card-title class="h5 pb-6"> {{ $t('user.amplify.Reset password') }} </v-card-title>
        <v-card-text class="pb-9">
          {{ $t('user.resetPassword.instructions') }}
        </v-card-text>
        <v-card-text class="mb-0 pb-6 pt-0">
          <v-text-field
            id="email"
            data-test="custom-email-input"
            :error-messages="emailResetErrors"
            v-model.trim="$v.email.$model"
            :placeholder="$t('user.amplify.Enter your email')"
            outlined
          ></v-text-field>
        </v-card-text>

        <v-card-text v-if="error" class="pt-0 d-flex align-center">
          <v-icon class="color-text-error material-icons font-size-large">error</v-icon>
          <p class="pa-0 ma-0" v-if="!showUserMigrationMessage">{{ parseErrorMessage(error) }}</p>
          <p
            v-else
            class="pa-0 ma-0"
            v-html="
              $t('user.resetPassword.messages.UserMigration', {
                // shows only for exporo
                resetLink: !isExporo
                  ? ''
                  : `<a class='color-text-primary-light' href='${$t('user.resetPassword.messages.UserMigration', {
                      context: 'linkPath',
                    })}'> ${$t('user.resetPassword.messages.UserMigration', { context: 'linkTitle' })}</a>`,
              })
            "
          />
        </v-card-text>

        <v-card-text class="pt-10">
          <BrandedButton
            type="primary"
            color="accent"
            :title="$t('user.amplify.Continue')"
            :onClick="sendCode"
            :disabled="loading"
            :loading="loading"
            block
          />
          <v-divider class="mt-8 mb-6" />

          <v-row class="pl-4 align-row">
            {{ $t('user.amplify.Have an account? ') }}
            <BrandedButton
              type="tertiary"
              :title="$t('user.amplify.Back Sign In')"
              :onClick="navigateSignIn"
              color="primary"
              text
              :inline="true"
            />
          </v-row>
        </v-card-text>
      </v-card>

      <!-- SENT CODE -->
      <v-card v-if="step === steps.SENT_CODE" class="pa-4">
        <v-card-title class="h5 pb-6"> {{ $t('user.amplify.Code sent') }} </v-card-title>
        <v-card-text class="pb-9">
          {{ $t('user.amplify.Code sent to email') }}
        </v-card-text>
        <SentEmailIcon />
        <v-card-text v-if="error" class="pt-0 d-flex align-center">
          <v-icon class="color-text-error material-icons font-size-large">error</v-icon>
          <p class="pa-0 ma-0">{{ parseErrorMessage(error) }}</p>
        </v-card-text>
        <v-card-text class="pt-10">
          <BrandedButton
            :title="$t('user.amplify.Continue')"
            type="primary"
            color="accent"
            block
            :onClick="() => step = steps.UPDATE_PASS"
            :disabled="loading"
            :loading="loading"
          />
          <v-divider class="mt-8 mb-6" />
          <v-row class="pl-4 align-row">
            {{ $t('user.amplify.Lost your code? ') }}
            <BrandedButton
              :key="'btn-code-1'"
              :title="$t('user.amplify.Resend Code')"
              type="tertiary"
              :onClick="sendCode"
              color="primary"
              text
            />
          </v-row>
          <v-row class="pl-4 align-row">
            {{ $t('user.amplify.Have an account? ') }}
            <BrandedButton
              :key="'btn-signin-1'"
              :title="$t('user.amplify.Back Sign In')"
              type="tertiary"
              :onClick="navigateSignIn"
              color="primary"
              text
            />
          </v-row>
        </v-card-text>
      </v-card>

      <!-- UPDATE PASSWORD -->
      <v-card v-if="step === steps.UPDATE_PASS" class="pa-4">
        <v-card-title>{{ $t('user.amplify.Create new password') }}</v-card-title>
        <v-card-text>
          <v-text-field
            id="code"
            v-model.trim="$v.code.$model"
            data-test="custom-code-input"
            type="number"
            :error-messages="codeErrors"
            :placeholder="$t('user.amplify.Confirmation Code')"
            outlined
            class="mt-5"
          />

          <v-text-field
            id="password"
            data-test="custom-sign-in-password-input"
            v-model="$v.password.$model"
            :error-messages="passwordErrors"
            :placeholder="$t('user.amplify.New Password')"
            :type="passwordVisible ? 'text' : 'password'"
            :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
            outlined
            @click:append="passwordVisible = !passwordVisible"
          ></v-text-field>

          <div class="authentication-wrapper__password-helper pa-4 mb-2">
            <div class="pb-1">{{ $t('user.amplify.Password must contain') }}</div>
            <ul>
              <li>{{ $t('user.amplify.passwordRules.rule1') }}</li>
              <li>{{ $t('user.amplify.passwordRules.rule2') }}</li>
              <li>{{ $t('user.amplify.passwordRules.rule3') }}</li>
              <li>{{ $t('user.amplify.passwordRules.rule4') }}</li>
              <li>{{ $t('user.amplify.passwordRules.rule5') }}</li>
            </ul>
          </div>
        </v-card-text>

        <v-card-text v-if="error" class="pt-0 d-flex align-center">
          <v-icon class="color-text-error material-icons font-size-large">error</v-icon>
          <p class="pa-0 ma-0">{{ parseErrorMessage(error) }}</p>
        </v-card-text>

        <v-card-text>
          <BrandedButton
            :title="$t('user.amplify.Change Password')"
            type="primary"
            :onClick="resetCode"
            color="accent"
            block
            :disabled="loading"
            :loading="loading"
          />

          <v-divider class="mt-8 mb-6" />

          <v-row class="pl-4 align-row">
            {{ $t('user.amplify.Lost your code? ') }}
            <BrandedButton
              :key="'btn-code-2'"
              :title="$t('user.amplify.Resend Code')"
              type="tertiary"
              :onClick="sendCode"
              color="primary"
              text
            />

          </v-row>
          <v-row class="pl-4 align-row">
            {{ $t('user.amplify.Have an account? ') }}
            <BrandedButton
              :key="'btn-signin-2'"
              :title="$t('user.amplify.Back Sign In')"
              type="tertiary"
              :onClick="navigateSignIn"
              color="primary"
              text
            />
          </v-row>

        </v-card-text>
      </v-card>

      <v-card v-if="step === steps.SUCCESS" class="pa-4">
        <v-card-title class="pt-0">
          <v-icon size="36px" dark class="authentication-wrapper__icon-success mt-5">check</v-icon>
        </v-card-title>
        <v-card-title class="h5">{{ $t('user.resetPassword.messages.Success') }}</v-card-title>
        <v-card-text class="mb-15">
          {{ $t('user.resetPassword.messages.Success', { context: 'text' }) }}
        </v-card-text>
        <v-card-text>
          <BrandedButton
            :title="$t('user.amplify.Sign In')"
            type="primary"
            color="accent"
            block
            :onClick="navigateSignIn"
            :disabled="loading"
            :loading="loading"
          />
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import { mapGetters } from 'vuex';
import SentEmailIcon from '../../components/reusable/icons/SentEmail.vue';
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators';
import { brandNames } from '@/enum/brands';
import BrandedButton from '@/components/reusable/BrandedButton';

// https://docs.amplify.aws/lib/auth/manageusers/q/platform/js

const RESET_STEPS = {
  GET_CODE: 'get-code',
  SENT_CODE: 'sent-code',
  UPDATE_PASS: 'update-password',
  SUCCESS: 'success',
};

export default {
  name: 'ResetPass',
  mounted() {
    this.$store.commit('setLoaded', { loaded: true });
  },
  components: {
    BrandedButton,
    SentEmailIcon,
  },
  validations: {
    code: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6),
    },
    password: {
      required,
    },
    email: {
      required,
      email,
    },
  },
  computed: {
    ...mapGetters(['brandSchema']),
    steps() {
      return RESET_STEPS;
    },
    isExporo() {
      return this.brandName === brandNames.EXPORO;
    },
    bgUrl() {
      if (!this.brandSchema.backgroundImage) {
        return null;
      }

      return require(`@/assets/${this.brandSchema.backgroundImage}`);
    },
    codeErrors() {
      const errors = [];
      if (!this.$v.code.$dirty) return errors;
      !this.$v.code.required && errors.push(this.$t('user.amplify.Code cannot be empty'));
      !this.$v.code.minLength && errors.push(this.$t('user.resetPassword.errorCodes.CodeMismatchException'));
      !this.$v.code.maxLength && errors.push(this.$t('user.resetPassword.errorCodes.CodeMismatchException'));

      return errors;
    },
    emailResetErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push(this.$t('user.amplify.Email required'));
      !this.$v.email.email && errors.push(this.$t('user.amplify.Invalid email format'));

      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push(this.$t('user.amplify.Password cannot be empty'));

      return errors;
    },
  },
  data() {
    return {
      loading: false,
      email: '',
      password: '',
      code: '',
      error: null,
      step: RESET_STEPS.GET_CODE,
      passwordVisible: false,
      showUserMigrationMessage: false,
    };
  },
  methods: {
    validateForm() {
      let isValid = false;

      switch (this.step) {
        case RESET_STEPS.UPDATE_PASS:
          isValid = !!this.email && !!this.code && !!this.password;
          break;
        default:
          isValid = false;
      }

      return isValid;
    },
    sendCode: async function () {
      this.$emit('submit');
      this.error = null;
      this.$v.$touch();

      if (this.$v.email.$invalid) {
        return false;
      }

      this.loading = true;
      const { redirectURL } = this.$route.query;

      const clientMetadata = {
        pathname: window.location.pathname,
        host: window.location.host,
        locale: this.$i18n.language,
      };

      try {
        await Auth.forgotPassword(this.email.toLowerCase(), clientMetadata);
        this.step = RESET_STEPS.SENT_CODE;
        this.$v.$reset();
      } catch (error) {
        this.error = error;
        console.log('error reseting password:', this.email.toLowerCase(), JSON.stringify(error));
        const exception = new Error();
        exception.name = `Auth.forgotPassword - ${error.code}`;
        exception.message = error.message;
        Sentry.captureException(exception);
      }

      this.loading = false;

      return true;
    },
    resetCode: async function () {
      this.$v.$touch();
      if (this.$v.code.$invalid || this.$v.password.$invalid) {
        return;
      }
      this.error = null;
      this.loading = true;

      const clientMetadata = {
        clientMetadata: JSON.stringify({
          pathname: window.location.pathname,
          host: window.location.host,
          locale: this.$i18n.language,
        }),
      };
      try {
        await Auth.forgotPasswordSubmit(this.email.toLowerCase(), this.code, this.password, clientMetadata);
        this.step = RESET_STEPS.SUCCESS;
      } catch (error) {
        this.error = error;
        console.log('error updating password:', this.email.toLowerCase(), JSON.stringify(error));
        const exception = new Error();
        exception.name = `Auth.forgotPasswordSubmit - ${error.code}`;
        exception.message = error.message;
        Sentry.captureException(exception);
      }
      this.loading = false;
    },
    navigateSignIn: function () {
      const { redirectURL } = this.$route.query;
      const returnURL = redirectURL ? redirectURL : `${window.location.origin}${window.location.pathname}#/`;

      this.$router.push({ name: 'SignIn', query: { ...this.$route.query, redirectURL: returnURL } });
    },

    parseErrorMessage(error) {
      this.showUserMigrationMessage = false;
      let keyExists = false;
      let key;
      let errorMessage;

      if (error.code) {
        if (error.code === 'UserNotFoundException') {
          this.showUserMigrationMessage = true;

          return null;
        }

        if (error.message.includes('security reasons')) {
          key = 'user.resetPassword.errorCodes.InvalidSecurity';
        }

        key = !key ? `user.resetPassword.errorCodes.${error.code}` : key;
      } else {
        key = `user.amplify.${error.message}`;
      }

      keyExists = this.$i18n.exists(key);

      if (keyExists) {
        errorMessage = this.$t(key);
      } else {
        errorMessage = this.$t('user.amplify.GenericError', { errorCode: error.code || 'Reset' });
      }

      return errorMessage;
    },

    togglePassword() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/auth.scss';

.authentication-wrapper {
  &__icon-success {
    background: color('primary');
    opacity: 0.5;
    border-radius: 50% !important;
    margin: 0 auto;
    width: 96px;
    height: 96px;
  }
  &__password-helper {
    background: #e7f3f1;
    font-size: 12px;
  }
  .align-row{
    align-items: center;
    gap: 1rem;
  }
}
</style>
